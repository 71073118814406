import styled from 'styled-components';

export const Container = styled.div`
    position: absolute;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0,0.5);
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    justify-content: center;
    align-items: center;
`;


export const Modal = styled.div`
    max-width: 80vw;
    max-height: 80vh;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background: #F5F5F5;
    padding: 40px 60px;

`;

export const Title = styled.h1`
    font-size: 40px;
    color: #143968;
    width: 100%;
    margin-top: 0px;
`;

export const Content = styled.div`
    background: #fff;
    color: #646464;
    overflow-y: scroll;
    margin-bottom: 20px;

    padding: 0px 22px;
    border-radius: 16px;
`;

export const Buttons = styled.div`
    display: flex;
    column-gap: 20px;

    button{
        width: 250px;
        padding: 4px 16px;
        justify-content: center;
        align-items: center;
        border-radius: 100px;
        color: #FFF;
        height: 40px;
        border: none;

        cursor: pointer;
        transition: 0.2s;
    }

    .cancel{
        background: #F06161;

        &:hover{
            background: #f38080;
        }
    }

    .accept{
        background: #AFAFAF;

        &:hover{
            background: #143968;
        }

    }

    .accept:disabled{
        background: #AFAFAF;
        cursor: not-allowed;

        &:hover{
            background: #AFAFAF;
        }
`;

export const Accept = styled.div`
    margin-bottom: 20px;
    input{
        margin-right: 10px;
    }
`;
