import React from "react";
import { Header, Container, SideMenu } from "../styles/inovaView";
import { Link } from "react-router-dom";
import IconHome from '../assets/home.png';
import IconSair from '../assets/sair.png';
import IconMaps from '../assets/maps.png';

function InovaView() {
  return (
    <>
        <Header>
          <div className="logo">
            <Link to={`/`}>
              <img src={IconHome} alt="InovaPATR" />
            </Link>
          </div>
          <div className="user">
            <span>Usuário</span>
            <Link to={`/logout`}>
              <img src={IconSair} alt="Sair" />
            </Link>
            </div>
        </Header>
        <Container>
        <SideMenu>
          <div>
            <img src={IconMaps} alt="Maps" />
          </div>
        </SideMenu>
          <iframe title="InovaView_Reports" 
              src="https://app.powerbi.com/reportEmbed?reportId=be35d68a-ff4a-46a7-bd0d-fd57e3cc8e1b&autoAuth=true&ctid=6f08e154-157d-4ecd-9c58-7b318ff4241e">
          </iframe>
        </Container>
    </>
  );
}

export default InovaView;
