import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyAMmhZQNjAzYIgx0tPtoZgsHzN7T_JXtQ8",
  authDomain: "inovally-data.firebaseapp.com",
  projectId: "inovally-data",
  storageBucket: "inovally-data.appspot.com",
  messagingSenderId: "141649570789",
  appId: "1:141649570789:web:da300c9a9b4d1b5e929d0c"
}

const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
