import styled from 'styled-components';

export const Header = styled.div`
    background-image: linear-gradient(90deg, #0A5282, #14D1AA);
    display: flex;
    justify-content: center;
    justify-content: space-between;
    padding: 16px 24px;
    
    img{
        cursor: pointer;
    }

    .logo{
        padding-right: 8px;
        border-right: 1px solid #70A6C0;
        img{
            height: 24px;
            width: 24px;
        }
    }

    .user{
        display: flex;
        align-items: center;
        span{
            color: #fff;
            margin-right: 16px;
        }
    }
`;

export const SideMenu = styled.div`
    background-image: linear-gradient( #0A5282, #14D1AA);
    width: 60px;
    height: calc(100vh - 60px);

    div{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 60px;
        background: #14D2AA;
    }
`;

export const Container = styled.div`
    display: flex;
    iframe{
        border: none;
        width: 100vw;
        height: calc(100vh - 72px);
    }
`;
