import React, { useEffect } from "react";
import { Container, Modal, Title, Content, Buttons, Accept } from "../styles/terms";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { getEmail } from "../services/auth";
// import api from "../services/api";

function Terms() {
    let navigate = useNavigate();
    const [accepted, setAccepted] = React.useState(false);
    const [scrolled, setScrolled] = React.useState(false);

    const handleAccept = async () => {
        await axios.post(`${process.env.REACT_APP_DATA_API}user/${getEmail()}`, 
            {'aceite': new Date()}, 
            { headers: 
                { 
                    Authorization: `64f7b70cc2a5f9cf8a3a8c08`,
                } 
            })
        .then((response) => {
                window.location.reload();
        })
        .catch((error) => {
            console.log(error);
        });
    }

    useEffect(() => {
        const handleScroll = () => {
            const contentDiv = document.getElementById("content");
            if (
                contentDiv &&
                contentDiv.offsetHeight + contentDiv.scrollTop >=
                contentDiv.scrollHeight -10
            ) {
                setScrolled(true);
            }
        };

        const contentDiv = document.getElementById("content");
        if (contentDiv) {
            contentDiv.addEventListener("scroll", handleScroll);
        }

        return () => {
            if (contentDiv) {
                contentDiv.removeEventListener("scroll", handleScroll);
            }
        };
    }, []);

    return (
        <Container>
            <Modal>
                <Title>Termos de Uso e Política de Privacidade dos Dados</Title>
                <Content id="content">
                    <div>
                        <h3>
                            POLÍTICA DE PRIVACIDADE PLATAFORMA INOVALLY
                        </h3>

                        <p>
                            OBJETIVO:
                        </p>

                        <p>
                            Esta Política estabelece as orientações gerais para a proteção de dados pessoais coletados pela Plataforma Inovally bem como, busca descrever todos os métodos, processos e procedimentos adotados sobre a coleta, uso, armazenamento, tratamento e proteção dos dados pessoais dos usuários e visitantes do site apps.inovally.com.br com a finalidade de demonstrar transparência e esclarecer a todos interessados sobre os tipos de dados que são coletados, os motivos da coleta e a forma como os usuários podem gerenciar ou excluir as suas informações pessoais.
                        </p>

                        <p>
                            A presente política de privacidade foi elaborada com base na Lei Geral de Proteção de Dados Pessoais (Lei 13.709/18), o Marco Civil da Internet (Lei 12.965/14) e a Lei de Acesso à Informação (Lei 12.527/11).
                        </p>

                        <p>
                            Entretanto, esse documento poderá ser atualizado em decorrência de eventual atualização normativa, motivo pelo qual, se convida o usuário a consultar periodicamente esta seção.
                        </p>

                        <p>
                            Pedimos que você se dedique alguns instantes a conhecer as nossas práticas de privacidade e, caso tenha quaisquer questões, contate-nos através do e-mail dpo@inovally.com.br ou submeta-nos um pedido através do formulário “CONTATO” disponível no nosso website.
                        </p>

                        <p>
                            Tentamos manter nossa Política tão simples quanto possível, mas caso não esteja familiarizado com os termos utilizados, tais como dados pessoais, tratamento de dados, titular de dados, controlador, operador, encarregado de dados, então leia primeiro e entenda estes conceitos-chaves descritos no tópico 2.
                        </p>

                        <p>
                            Além disso, a plataforma se compromete a cumprir as normas previstas na Lei Geral de Proteção de Dados (LGPD), e respeitar os princípios dispostos no Art. 6º:
                        </p>

                        <p>
                            I - finalidade: realização do tratamento para propósitos legítimos, específicos, explícitos e informados ao titular, sem possibilidade de tratamento posterior de forma incompatível com essas finalidades;
                        </p>

                        <p>
                            II - adequação: compatibilidade do tratamento com as finalidades informadas ao titular, de acordo com o contexto do tratamento;
                        </p>

                        <p>
                            III - necessidade: limitação do tratamento ao mínimo necessário para a realização de suas finalidades, com abrangência dos dados pertinentes, proporcionais e não excessivos em relação às finalidades do tratamento de dados;
                        </p>

                        <p>
                            IV - livre acesso: garantia, aos titulares, de consulta facilitada e gratuita sobre a forma e a duração do tratamento, bem como sobre a integralidade de seus dados pessoais;
                        </p>

                        <p>
                            V - qualidade dos dados: garantia, aos titulares, de exatidão, clareza, relevância e atualização dos dados, de acordo com a necessidade e para o cumprimento da finalidade de seu tratamento;
                        </p>

                        <p>
                            VI - transparência: garantia, aos titulares, de informações claras, precisas e facilmente acessíveis sobre a realização do tratamento e os respectivos agentes de tratamento, observados os segredos comercial e industrial;
                        </p>

                        <p>
                            VII - segurança: utilização de medidas técnicas e administrativas aptas a proteger os dados pessoais de acessos não autorizados e de situações acidentais ou ilícitas de destruição, perda, alteração, comunicação ou difusão;
                        </p>

                        <p>
                            VIII - prevenção: adoção de medidas para prevenir a ocorrência de danos em virtude do tratamento de dados pessoais;
                        </p>

                        <p>
                            IX - não discriminação: impossibilidade de realização do tratamento para fins discriminatórios ilícitos ou abusivos;
                        </p>

                        <p>
                            X - responsabilização e prestação de contas: demonstração, pelo agente, da adoção de medidas eficazes e capazes de comprovar a observância e o cumprimento das normas de proteção de dados pessoais e, inclusive, da eficácia dessas medidas. Esta Política de Privacidade poderá ser atualizada em decorrência de eventual atualização normativa, razão pela qual se convida o usuário a consultar periodicamente esta seção
                        </p>

                        <p>
                            CONCEITOS:
                        </p>

                        <p>
                            O QUE É A LEI GERAL DE PROTEÇÃO DE DADOS?
                        </p>

                        <p>
                            A Lei Geral de Proteção de Dados regulamenta o tratamento de dados pessoais, dispostos em meio físico ou digital, feito por pessoa física ou jurídica de direito público ou privado. Sendo assim, desde sua entrada em vigor em setembro/2020, tornou-se obrigatória a adequação à Lei em todas as empresas e órgãos públicos.
                        </p>

                        <p>
                            Essa lei tem por escopo garantir mais transparência ao titular dos dados pessoais e traz princípios importantes a serem observados, tais como a transparência, segurança, necessidade e adequação no uso de dados pessoais.
                        </p>

                        <p>
                            O QUE É TRATAMENTO DE DADOS PESSOAIS?
                        </p>

                        <p>
                            Entende-se por tratamento de dados toda operação realizada com dados pessoais, desde o início, que é o processo de coleta, seguido do armazenamento, uso e o descarte efetivo da informação.
                        </p>

                        <p>
                            QUEM É O TITULAR DE DADOS?
                        </p>

                        <p>
                            Titular de dados é toda pessoa natural a quem se referem os dados objeto do tratamento. No caso, é toda pessoa que acessa o site a Plataforma Inovally.
                        </p>

                        <p>
                            QUEM É O CONTROLADOR DE DADOS?
                        </p>

                        <p>
                            É a pessoa natural ou jurídica, de direito público ou privado, a quem compete as decisões referentes ao tratamento de dados pessoais.
                        </p>

                        <p>
                            QUEM É O OPERADOR DE DADOS?
                        </p>

                        <p>
                            É a pessoa natural ou jurídica, de direito público ou privado, que realiza o tratamento de dados pessoais em nome do controlador.
                        </p>

                        <p>
                            QUEM É O ENCARREGADO DE DADOS?
                        </p>

                        <p>
                            É a pessoa indicada pelo controlador para atuar como canal de comunicação entre os titulares dos dados, a Autoridade Nacional de Proteção de Dados, e a Inovally.
                        </p>

                        <p>
                            No caso, o Encarregado de Dados é Lorena Santos.
                        </p>

                        <p>
                            O QUE É CONSENTIMENTO?
                        </p>

                        <p>
                            É toda manifestação livre, informada e inequívoca pela qual o Titular concorda com o Tratamento de seus Dados Pessoais para uma finalidade determinada.
                        </p>

                        <p>
                            O QUE É ANONIMIZAÇÃO?
                        </p>

                        <p>
                            É a utilização de meios técnicos razoáveis e disponíveis no momento do tratamento, por meio dos quais um dado perde a possibilidade de associação, direta ou indireta, a um indivíduo.
                        </p>

                        <p>
                            QUEM COLETA OS DADOS PESSOAIS?
                        </p>

                        <p>
                            Quaisquer dados pessoais fornecidos ou recolhidos pela Plataforma Inovally, através de outras tecnologias de monitorização, são tratados pela própria Inovally, que é responsável pelo tratamento de dados.
                        </p>

                        

                        <p>
                            QUAIS DADOS PESSOAIS SÃO COLETADOS? 
                            </p>

                        <p>
                            Os dados pessoais coletados do titular de dados, que no caso, são os cadastrados na Plataforma Inovally são os seguintes:  
                            </p>

                        <p>
                            Dados para a criação da conta/perfil na plataforma: nome, CPF, data nascimento, cargo, endereço, telefone e e-mail. 
                            </p>

                        <p>
                            Dados para otimização da navegação: acesso a páginas, palavras-chave utilizadas na busca, comentários e endereço anônimo de IP para finalidade de análise estatística de acesso. 
                            </p>

                        <p>
                            Não é obrigado (se de fato não for) fornecer à Plataforma Inovally os dados pessoais que solicitamos, mas, caso escolha não o fazer, poderemos não conseguir oferecer-lhe os nossos produtos e serviços, ou um serviço de alta qualidade ou responder a alguma questão que possa ter. 
                            </p>

                        

                        

                        <p>
                            QUAL O MODO QUE UTILIZAMOS PARA RECOLHER SEUS DADOS PESSOAIS? 
                            </p>

                        <p>
                            Podemos recolher seus dados pessoais de várias formas, que incluem: 
                            </p>

                        <p>
                            Dados pessoais que nos fornece diretamente. Coletamos as informações que nos fornece no momento do cadastro de login. 
                            </p>

                        <p>
                            Dados pessoais que coletamos automaticamente. Coletamos e guardamos também certos tipos de dados pessoais sempre que interage conosco online. Por exemplo, utilizamos cookies e tecnologias de monitorização (para saber mais, consulte os nossos conceitos-chave de privacidade bem como a nosso Política de Cookies) para obter dados pessoais sempre que o seu navegador de internet acede aos nossos websites ou publicidades e a outros conteúdos oferecidos pela Plataforma Inovally ou em seu nome em outros websites. Exemplos dos tipos de dados pessoais que coletamos incluem endereço IP, ID do Dispositivo, dados de localização, informações sobre o computador e a ligação tais como o tipo e versão de browser, definições de fuso horário, tipos e versões de plug-ins utilizadas pelo browser, sistema operativo, e histórico que a Plataforma Inovally por vezes agrega com informação similar de outros consumidores.  
                            </p>

                        <p>
                            Durante parte da sua navegação nos websites poderemos também utilizar ferramentas de software para medir e recolher informações sobre a sessão, incluindo tempos de resposta das páginas, erros de download, duração da visita a certas páginas, informação relativa à sua interação com elas, e os métodos utilizados para sair das mesmas. Poderemos também recolher informação técnica para nos ajudar a identificar o seu dispositivo para fins de prevenção à fraude e de diagnóstico. 
                            </p>
                        
                        

                        <p>
                            QUAL A FINALIDADE DA COLETA DOS DADOS PESSOAIS? 
                            </p>

                        <p>
                            Nós coletamos, tratamos e divulgamos os seus dados pessoais apenas para finalidades específicas e limitadas, conforme descrito abaixo: 
                            </p>

                        <p>
                            Bem-estar do usuário e visitante: aprimorar o produto e/ou serviço oferecido, facilitar, agilizar e cumprir os compromissos estabelecidos entre o usuário e a empresa, melhorar a experiência dos usuários e fornecer funcionalidades específicas a depender das características básicas do usuário. 
                            </p>

                        <p>
                            Melhorias da plataforma: compreender como o usuário utiliza os serviços da plataforma, para ajudar no desenvolvimento de negócios e técnicas. 
                            </p>

                        <p>
                            Comercial: os dados são usados para personalizar o conteúdo oferecido e gerar subsídio à plataforma para a melhora da qualidade no funcionamento dos serviços. 
                            </p>

                        <p>
                            Dados de cadastro: para permitir o acesso do usuário a determinados conteúdos da plataforma, exclusivo para usuários cadastrados. 
                            </p>

                        <p>
                            O tratamento de dados pessoais para finalidades não previstas nesta Política de Privacidade somente ocorrerá mediante comunicação prévia ao usuário, de modo que os direitos e obrigações aqui previstos permanecem aplicáveis. 
                            </p>
                        

                        <p>
                            QUAIS OS DIREITOS DOS TITULARES? 
                            </p>

                        <p>
                            É titular de um conjunto de direitos em relação aos seus dados pessoais e ao modo como são tratados. Poderá exercer esses direitos em qualquer momento. Fornecemos abaixo um resumo geral desses direitos, juntamente com aquilo que implicam para si. Pode exercer os seus direitos enviando-nos um e-mail ou submetendo um pedido através do nosso formulário “fale conosco” disponível nos nossos websites. 
                            </p>

                        <p>
                            Nos casos em que tratamos os seus dados pessoais têm vários direitos sobre o modo como os dados são tratados, e pode exercer esses direitos a qualquer momento. Fornecemos abaixo uma visão geral desses direitos – juntamente com aquilo que implicam para si. 
                            </p>

                        <p>
                            O direito à informação. O titular dos dados tem direito a que lhe seja concedida informação concisa, transparente, compreensível e de fácil acesso, utilizando uma linguagem clara e simples, sobre o modo como utilizamos os seus dados pessoais e sobre os seus direitos. Nestes termos, apresentamos essa informação nesta Política de Privacidade.   
                        </p>
                        <p>
                            O direito de acesso e retificação. O titular dos dados tem direito a aceder aos seus dados pessoais, bem como a retificar os dados pessoais inexatos que lhe digam respeito, ou a completá-los caso os mesmos se mostrem incompletos. 
                        </p>
                        <p>
                            O direito à portabilidade dos dados. Os dados pessoais que nos facilitou podem ser portáveis. Isto significa que poderão ser transferidos, copiados ou transmitidos eletronicamente em certas circunstâncias. 
                        </p>
                        <p>
                            O direito ao apagamento dos dados. Em determinadas condições, o titular dos dados tem o direito de obter do responsável pelo tratamento o apagamento dos seus dados pessoais, sem demora justificada. Caso deseje apagar os seus dados pessoais, por favor informe-nos e tomaremos as medidas razoáveis para responder ao seu pedido de acordo com os requisitos legais. Caso os dados pessoais que coletamos deixem de ser necessários para qualquer fim e não formos por lei obrigados a mantê-los, desenvolveremos os nossos melhores esforços no sentido de os eliminar, destruir ou anonimizar definitivamente.  
                        </p>
                        <p>
                            O direito à limitação do tratamento. Em determinadas condições, o titular dos dados tem o direito de limitar o tratamento dos seus dados pessoais. 
                        </p>
                        <p>
                            O direito de oposição. O titular dos dados tem o direito de se opor a qualquer momento, por motivos relacionados com a sua situação particular, ao tratamento dos dados pessoais que lhe digam respeito, incluindo a definição de perfis nos termos da Lei Geral de Proteção de Dados. Nomeadamente, quando os dados pessoais forem tratados para efeitos de comercialização direta, o titular dos dados tem o direito de se opor a qualquer momento ao tratamento dos dados pessoais que lhe digam respeito para os efeitos da referida comercialização (ex. receber e-mails de notificação nossos ou ser contatado com potenciais oportunidades variadas). 
                        </p>
                        <p>
                            O direito a apresentar uma queixa a Autoridade Nacional de Proteção de Dados. Tem o direito de apresentar reclamação a Autoridade Nacional de Proteção de Dados sobre o modo como tratamos os seus dados pessoais.  
                        </p>
                        <p>
                            O direito a retirar o consentimento. Caso tenha dado o seu consentimento à nossa utilização dos seus dados pessoais (ex., dependemos do seu consentimento como base legal para tratar os seus dados pessoais), o titular tem o direito de retirar o seu consentimento em qualquer momento (a retirada do consentimento não compromete a licitude do tratamento efetuado com base no consentimento previamente dado), comunicando-nos a sua intenção através dos contatos fornecidos abaixo. 
                        </p>
                        <p>
                            Direitos relacionados com a tomada de decisões automatizadas. O titular dos dados tem o direito de não ser sujeito a decisões unicamente baseadas em processos automatizados que produzam efeitos legais ou outros efeitos significativos. Em particular, tem o direito de:  
                        </p>
                        <p>
                            obter intervenção humana; 
                        </p>
                        <p>
                            expressar o seu ponto de vista; 
                        </p>
                        <p>
                            obter uma justificação da decisão obtida após uma avaliação; 
                        </p>
                        <p>
                            e contestar tal decisão. 
                        </p>
                        <p>
                            POR QUANTO TEMPO OS DADOS PESSOAIS FICAM ARMAZENADOS? 
                        </p>
                        <p>
                            Os dados pessoais do usuário e visitante são armazenados pela plataforma durante o período necessário para a prestação do serviço ou o cumprimento das finalidades previstas no presente documento, conforme o disposto no inciso I do artigo 15 da Lei 13.709/18. 
                        </p>
                        <p>
                            Os dados podem ser removidos ou anonimizados a pedido do usuário, excetuando os casos em que a lei oferecer outro tratamento. 
                        </p>
                        <p>
                            Ainda, os dados pessoais dos usuários e visitantes apenas podem ser conservados após o término de seu tratamento nas seguintes hipóteses previstas no artigo 16 da referida lei: 
                        </p>
                        <p>
                            I – cumprimento de obrigação legal ou regulatória pelo controlador; 
                        </p>
                        <p>
                            II – estudo por órgão de pesquisa, garantida, sempre que possível, a anonimização dos dados pessoais; 
                        </p>
                        <p>
                            III – Políticas públicas; 
                        </p>
                        <p>
                            III – transferência a terceiro, desde que respeitados os requisitos de tratamento de dados dispostos nesta Lei; 
                        </p>
                        <p>
                            IV – uso exclusivo do controlador, vedado seu acesso por terceiro, e desde que anonimizados os dados. 
                        </p>
                        <p>
                            SEGURANÇA DOS DADOS PESSOAIS: 
                        </p>
                        <p>
                            A plataforma se compromete a aplicar as medidas técnicas e organizativas aptas a proteger os dados pessoais de acessos não autorizados e de situações de destruição, perda, alteração, comunicação ou difusão de tais dados. 
                        </p>
                        <p>
                            A plataforma não se exime de responsabilidade por culpa exclusiva de terceiros, como em caso de ataque de hackers ou crackers, ou culpa exclusiva do usuário, como no caso em que ele mesmo transfere seus dados a terceiros. O site se compromete a comunicar o usuário em caso de alguma violação de segurança dos seus dados pessoais. 
                        </p>
                        <p>
                            Os dados pessoais armazenados são tratados com confidencialidade, dentro dos limites legais. No entanto, podemos divulgar suas informações pessoais caso sejamos obrigados pela lei para fazê-lo ou se você violar nossos Termos de Serviço. 
                        </p>
                        <p>
                            COMPARTILHAMENTO DE DADOS: 
                        </p>
                        <p>
                            O compartilhamento de dados do usuário ocorre apenas com os dados de identificação dentro da própria plataforma para os demais usuários. 
                        </p>
                        <p>
                            Os dados pessoais do usuário coletados automaticamente e fornecidos pelo titular não são compartilhados pela Inovally exteriormente a plataforma para terceiros. 
                        </p>
                        <p>
                            CONSENTIMENTO: 
                        </p>
                        <p>
                            Ao utilizar os serviços e fornecer as informações pessoais na plataforma, o usuário e visitante está consentindo com a presente Política de Privacidade. 
                        </p>
                        <p>
                            O usuário, ao cadastrar-se, manifesta conhecer e pode exercitar seus direitos de cancelar seu cadastro, acessar e atualizar seus dados pessoais e garante a veracidade das informações por ele disponibilizadas. 
                        </p>

                        <p>
                            Igualmente é assegurado ao usuário tem o direito de retirar o seu consentimento quando desejar. 
                        </p>
                        <p>
                            COMO CONTATAR A EMPRESA: 
                        </p>
                        <p>
                            O Encarregado da Proteção de Dados (DPO) nomeado pela Inovally, pode ser contatado no endereço de e-mail: dpo@inovally.com.br  
                        </p>
                        <p>
                            Caso tenha quaisquer questões ou preocupações sobre o Termo de Privacidade da Plataforma Inovally ou o tratamento de dados ou deseje apresentar uma reclamação sobre uma possível violação das leis de privacidade, por favor contate-nos através do e-mail ou submetendo um pedido através do nosso formulário “fale conosco” disponível nos nossos websites.  
                        </p>
                        <p>
                            ALTERAÇÕES NA POLÍTICA DE PRIVACIDADE: 
                        </p>
                        <p>
                            É plenamente possível que haja alteração nesta Política de Privacidade a qualquer momento, por isso, é recomendado que o usuário a visite com frequência. 
                        </p>
                    </div>
                    <div>
                        <h3>TERMOS DE USO</h3> 
                        <p>1.	DEFINIÇÕES 
                        </p>
                        <p>As seguintes definições são aplicáveis aos termos definidos neste instrumento: 
                        </p>
                        <p>“Inovally” – Proprietária e operadora da plataforma. 
                        </p>
                        <p>“Cliente” – Órgão Público. 
                        </p>
                        <p>“Usuário” – Servidor que utiliza a plataforma em nome da prefeitura. 
                        </p>
                        <p>“Conta” – Meio através do qual Usuário acessa e utiliza a plataforma. 
                        </p>
                        <p>“Plataforma” – Sistema acessível através do site (anexar url). 
                        </p>

                        <p>2.	ACEITAÇÃO E MODIFICAÇÃO DOS TERMOS DE USO 
                        </p>
                        <p>2.1.	Ao utilizar quaisquer das funcionalidades da Plataforma, o Usuário confirma que leu, entendeu e aceitou o presente Termo em sua totalidade, sem qualquer modificação. 
                        </p>
                        <p>2.2.	A Inovally poderá alterar qualquer parte deste Termo em boa-fé, mediante notificação ao Cliente e Usuário, bem como publicação em seu site da versão atualizada. Caso o Cliente, ou qualquer Usuário, continue utilizando e/ou de qualquer forma acessando a Plataforma, será considerado para todos os fins e efeitos de direito, que o Cliente, ou qualquer Usuário, concordou com a nova versão do Termos de Uso. 
                        </p>

                        <p>3.	CONDIÇÕES PARA O ACESSO E USO DA PLATAFORMA 
                        </p>
                        <p>3.1.	A Inovally concede ao Cliente licença de uso não exclusiva, intransferível, não sublicenciável, limitada e revogável para acessar e utilizar a Plataforma por meio de seus Usuários, condicionado ao cumprimento das regras da Inovally estabelecidas neste documento. 
                        </p>
                        <p>3.2.	Para utilizar a Plataforma, será necessário o Usuário realizar o cadastro de criação de conta na plataforma enviando os dados solicitados que serão tratados em conformidade com nossa Política de Privacidade. Após a criação da Conta, o Usuário passará a ter acesso às funcionalidades da Plataforma por meio de login e senha, dados esses que se compromete a não divulgar a terceiros, ficando sob sua exclusiva responsabilidade qualquer uso do login e senha de sua titularidade. 
                        </p>
                        <p>3.3.	O Usuário se compromete a utilizar a Plataforma apropriadamente, obrigando-se a: 
                        </p>
                        <p>a)	Não compartilhar seu login e senha com terceiros; 
                        </p>
                        <p>b)	Não utilizar aparelhos de uso pessoal para acessar a plataforma; 
                        </p>
                        <p>c)	Comunicar a Inovally imediatamente caso notar movimentação estranha em sua conta ou caso tenha divulgado sua senha por qualquer motivo; 
                        </p>
                        <p>d)	Abster-se de realizar o compartilhamento de dados por meios alheios ao da plataforma;  
                        </p>
                        <p>e)	Manter o dispositivo de acesso à plataforma seguro, com o uso de ferramentas disponíveis, como antivírus, firewall, entre outras, de modo a contribuir na prevenção de riscos eletrônicos. 
                        </p>
                        <p>(condições exclusivas relacionadas a funcionalidade da plataforma) 
                        </p>


                        
                        <p>4.	RESPONSABILIDADES: 
                        </p>
                        <p>4.1.	A Inovally não será responsabilizada: 
                        </p>
                        <p>a)	Por danos e prejuízos causados por uso não autorizado do login e senha do Usuário, com ou sem seu conhecimento. 
                        </p>
                        <p>b)	Pela veracidade dos dados inseridos na plataforma pelo Usuário. 
                        </p>
                        <p>c)	Por danos e prejuízos decorrentes de fraudes ou ações ilegais realizadas pelos Usuários ou terceiros na plataforma. 
                        </p>
                        <p>d)	Por interrupções ou suspensões de conexão, transmissões de computador incompletas ou que falhem, bem como por falha técnica de qualquer tipo, incluindo, mas não se limitando, ao mau funcionamento eletrônico de qualquer rede, hardware ou software. 
                        </p>


                        
                        <p>5.	PROPRIEDADE INTELECTUAL 
                        </p>
                        <p>5.1.	Toda mídia, software, imagem, vídeo, texto, marca, tecnologia, algoritmo e outras propriedades intelectuais da plataforma são de propriedade exclusiva da Inovally. 
                        </p>
                        <p>5.2.	Qualquer uso não autorizado do conteúdo da Inovally será considerado como violação dos direitos autorais e industriais da Inovally. 
                        </p>
                        <p>5.3.	É proibido usar, copiar, reproduzir, modificar, traduzir, publicar, transmitir, distribuir, executar, fazer o upload, exibir, licenciar, vender, explorar, fazer scrapping e/ou engenharia reversa do conteúdo da Inovally, para qualquer finalidade, sem o consentimento prévio e expresso da Inovally. 
                        </p>

                        <p>6.	SUSPENSÃO DE CONTA 
                        </p>
                        <p>6.1.	A Inovally poderá suspender uma conta de Usuário imediatamente para investigar um possível risco de segurança ou uma possível violação a este Termo. 
                        </p>

                        <p>7.	DISPOSIÇÕES FINAIS 
                        </p>
                        <p>7.1.	Estes Termos de Uso constituem a versão válida e eficaz dos termos e condições que regem a relação entre a Inovally e o Cliente. 
                        </p>
                        <p>7.2.	Existindo dúvidas sobre estes Termos de Uso, o Usuário poderá entrar em contato com a Inovally através do suporte com o Encarregado da Proteção de Dados (DPO) nomeado pela Inovally, que pode ser contatado no endereço de e-mail: dpo@inovally.com.br. 
                        </p>
                    </div>
                </Content>
                <Accept>
                    <input disabled={!scrolled} type="checkbox" name="accepted" value={accepted} onChange={() => setAccepted(!accepted)} id="accept" />
                    <label htmlFor="accept">Eu li e concordo com os Termos de Uso e com a Política de Privacidade de dados.</label>
                </Accept>
                <Buttons>
                    <button className="cancel" onClick={() => navigate('/logout')}>Cancelar</button>
                    <button disabled={!accepted} onClick={() => handleAccept()} className="accept">Aceitar</button>
                </Buttons>
            </Modal>
        </Container>
    );
}

export default Terms;